import { inject } from '@angular/core';
import { CrmMessageService } from 'common-module/message';
import { crmCreateModalFormFn$ } from 'common-module/modal';
import { ModalOptions } from 'ng-zorro-antd/modal';
import { EMPTY, forkJoin, map, switchMap } from 'rxjs';

import { OrganizationService } from '~/api/organization/organization.service';
import { UserService } from '~/api/user/user.service';
import { mapCommunityToOptionValue } from '~/shared/modal/schedule-visit/map-community-to-option';

import { ScheduleVisitFormProvider } from './schedule-visit-form.provider';
import { ScheduleVisitProvider } from './schedule-visit.provider';

/**
 * Factory function to get open schedule visit function
 */
export const openScheduleVisitFn = () => {
  const modal = crmCreateModalFormFn$();
  const organization = inject(OrganizationService);
  const user = inject(UserService);
  const message = inject(CrmMessageService);

  return (
    data: Omit<ScheduleVisitProvider['data'], 'communities'> & {
      selectDefaultOrFirstCommunity?: true;
    },
    settings?: ModalOptions,
  ) =>
    forkJoin({
      currentOrganization: organization.organization$,
      communities: organization.listCommunitiesAll(),
      author: user.user$,
    }).pipe(
      map(({ communities, author, currentOrganization }) => {
        if (data.community) {
          return [data.community];
        }

        const userCommunities = new Set(
          (author.membership ?? [])
            .filter(
              ({ type, organization }) =>
                type === 'community' &&
                currentOrganization === organization?._id,
            )
            .map(({ ref }) => ref),
        );

        return communities
          .filter(
            ({ settings, _id }) =>
              userCommunities.has(_id) &&
              settings &&
              (!settings.viewMembers || settings.telehealthVisit) &&
              settings.patientTypes?.length &&
              settings.serviceTypes?.length &&
              settings.telehealthVisitDurations?.length,
          )
          .map((community) => mapCommunityToOptionValue(community));
      }),
      switchMap((communities) => {
        if (!communities.length) {
          message.error('scheduleVisit.missingCommunity');
          return EMPTY;
        }

        return modal({
          title: data.title ?? 'scheduleVisit.title',
          formProvider: ScheduleVisitFormProvider,
          modalProvider: ScheduleVisitProvider,
          data: {
            ...data,
            communities,
            community: data.selectDefaultOrFirstCommunity
              ? (communities.find((c) => c.isDefault) ?? communities[0])
              : undefined,
          },
          settings,
        });
      }),
    );
};
